import React, {useState} from 'react';
import Label from "../../helpers/Label";
import {useTheme} from "@mui/material/styles";

const StatusChipsComponent = ({subservices}) => {
    const theme = useTheme();
    const [statusChipsExpanded, setStatusChipsExpanded] = useState(false);
    const handleSlideDown = () => {
        setStatusChipsExpanded(!statusChipsExpanded)
    }
    return <div className={statusChipsExpanded ? "status-chip-wrapper expanded" : "status-chip-wrapper"}
         onClick={handleSlideDown}>
        {subservices?.split(",").map((item, i) => (
            <Label
                key={item}
                variant={
                    theme.palette.mode === "light"
                        ? "ghost"
                        : "filled"
                }
                color={"secondary"}
            >
                <span>{item}</span>
            </Label>
        ))}
    </div>

};

export default StatusChipsComponent;
