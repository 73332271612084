import {
  ADD_ACTIVE_SUB_SERVICE,
  ADD_SERVICE,
  ADD_SHIFT,
  DELETE_ACTIVE_SUB_SERVICE,
  DELETE_SELECTED_TIMEDURATIONS,
  DELETE_SERVICE,
  DELETE_SHIFT,
  GET_ORDERS,
  GET_SERVICES,
  GET_SHIFTS,
  GET_SUB_SERVICES,
  HANDLE_ACTIVE_RESERVATION_CHANGE,
  HANDLE_ACTIVE_RESERVATION_ITEMS_CHANGE,
  HANDLE_ACTIVE_RESERVATION_ITEMS_DELETE,
  SET_ALL_SELECTED_TIMEDURATIONS,
  SET_GLOBAL_SEARCH,
  SET_INITAL_TIMEDURATIONS,
  SET_ORDER_DATE,
  SET_PREV_TIME_RANGE,
  SET_RESERVATION,
  SET_RESERVATION_COLOR,
  SET_RESERVATION_STOP,
  SET_SELECTED_SERVICE,
  SET_SELECTED_SHIFT,
  SET_SELECTED_TIMEDURATIONS,
  SET_IS_LOADING_STATE,
  SET_OPENED_POPOVER_RESRVATIONS,
  HANDLE_SELECT_ALL_ACTIVE_RESERVATION_ITEMS_CHANGE,
  SET_SEARCH_TERM,
  SET_DYNAMIC_VAR,
  UPDATE_ORDERS,
  SET_ARRIVED_FILLTER,
} from "../actions";

const bookingReducer = (state, { type, payload }) => {
  switch (type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: payload.orders,
      };

    case UPDATE_ORDERS:
      const index = state.orders.findIndex((object) => {
        return object.id * 1 === payload.order.id * 1;
      });

      const filteredArray = [...state.orders];
      filteredArray[index] = payload.order;

      return {
        ...state,
        orders: [...filteredArray],
      };

    case SET_IS_LOADING_STATE:
      return {
        ...state,
        isLoadingState: true,
      };

    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: payload.term,
      };

    case SET_DYNAMIC_VAR:
      return {
        ...state,
        dynamicVar: parseInt(payload.value + Math.random()),
      };

    case SET_ORDER_DATE:
      return {
        ...state,
        orderDate: payload.date,
      };

    case SET_OPENED_POPOVER_RESRVATIONS:
      return {
        ...state,
        openedPopoverReservation: payload.resId,
      };

    case SET_SELECTED_TIMEDURATIONS:
      const filteredTimeDurations = state.selectedTimeDurations?.filter(
        (item) => item.id !== payload?.timeDuration.id,
      );

      return {
        ...state,
        selectedTimeDurations: [...filteredTimeDurations, payload.timeDuration],
        isLoadingState: false,
      };

    case SET_ALL_SELECTED_TIMEDURATIONS:
      return {
        ...state,
        selectedTimeDurations: [
          ...state.selectedTimeDurations,
          ...payload.timeDurations,
        ],
        isLoadingState: false,
      };

    case SET_INITAL_TIMEDURATIONS:
      const filteredStateItemsInOrder =
        state.currentlyEditedReservation.items?.filter(
          (item) => item.state_item !== true,
        );

      return {
        ...state,
        selectedTimeDurations: [],
        currentlyEditedReservation: {
          ...state.currentlyEditedReservation,
          items: [...filteredStateItemsInOrder],
        },
      };

    case DELETE_SELECTED_TIMEDURATIONS:
      return {
        ...state,
        selectedTimeDurations: state.selectedTimeDurations.filter(
          (timeD) => timeD.id !== payload.timeDurationId,
        ),
      };

    case SET_RESERVATION_STOP:
      return {
        ...state,
        reservationStoped: payload.stopId,
      };

    case SET_SELECTED_SERVICE:
      return {
        ...state,
        selectedService: payload.service,
      };

    case SET_SELECTED_SHIFT:
      return {
        ...state,
        selectedShift: payload.shift,
        selectedService: null,
      };

    case GET_SERVICES:
      return {
        ...state,
        services: payload.services,
      };

    case GET_SUB_SERVICES:
      return {
        ...state,
        subservices: payload.subservices,
      };

    case GET_SHIFTS:
      return {
        ...state,
        shifts: payload.shifts,
      };

    case ADD_SERVICE:
      return {
        ...state,
        services: [...state.services, payload.service],
      };

    case ADD_SHIFT:
      return {
        ...state,
        shifts: [...state.shifts, payload.shift],
      };

    case DELETE_SERVICE:
      return {
        ...state,
        services: state.services.filter(
          (service) => service.id !== payload.serviceId,
        ),
      };

    case DELETE_SHIFT:
      return {
        ...state,
        shifts: state.shifts.filter((shift) => shift.id !== payload.shiftId),
      };

    case SET_GLOBAL_SEARCH:
      return {
        ...state,
        isGlobalSearchOpen: payload.val,
      };

    case ADD_ACTIVE_SUB_SERVICE:
      return {
        ...state,
        activeSubServices: [...state.activeSubServices, payload.subserviceId],
      };

    case DELETE_ACTIVE_SUB_SERVICE:
      return {
        ...state,
        activeSubServices: state.activeSubServices.filter(
          (subs) => subs !== payload.subserviceId,
        ),
      };

    case SET_RESERVATION:
      return {
        ...state,
        currentlyEditedReservation: payload.reservation,
      };
    case HANDLE_ACTIVE_RESERVATION_CHANGE:
      return {
        ...state,
        currentlyEditedReservation: {
          ...state.currentlyEditedReservation,
          [payload.name]: payload.value,
        },
      };

    case HANDLE_SELECT_ALL_ACTIVE_RESERVATION_ITEMS_CHANGE:
      return {
        ...state,
        currentlyEditedReservation: {
          ...state.currentlyEditedReservation,
          items: [
            ...state.currentlyEditedReservation.items,
            ...payload.newItems,
          ],
        },
      };

    case HANDLE_ACTIVE_RESERVATION_ITEMS_CHANGE:
      const filteredItems = state.currentlyEditedReservation.items?.filter(
        (item) => item.id !== payload.value.id,
      );

      return {
        ...state,
        currentlyEditedReservation: {
          ...state.currentlyEditedReservation,
          items: [...filteredItems, payload.value],
        },
      };

    case HANDLE_ACTIVE_RESERVATION_ITEMS_DELETE:
      return {
        ...state,
        currentlyEditedReservation: {
          ...state.currentlyEditedReservation,
          items: state.currentlyEditedReservation.items.filter(
            (item) => item.id !== payload.itemId,
          ),
        },
      };

    case SET_PREV_TIME_RANGE:
      return {
        ...state,
        availabilityRange: payload.value,
      };
    case SET_RESERVATION_COLOR:
      return {
        ...state,
        activeReservationColor: payload.value,
      };
    default:
      return state;
  }
};

export default bookingReducer;
