import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";

const GridPageWrapper = styled(Grid)(({ theme }) => ({
  maxWidth: "1200px",
  margin: "0 auto",
  padding: "15px",

  "@media (min-width: 992px)": {
    padding: "25px 50px",
  },

  ".MuiLink-root": {
    textDecoration: "none",
  },
}));

export default GridPageWrapper;
