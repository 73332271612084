import { Button, Grid,Skeleton } from "@mui/material";
import GridPageWrapper from "../../assets/wrappers/GridPageWrapper";
import SingleUserList from "../../components/dashboard/user/SingleUserList";
import HeaderBreadcrumbs from "../../components/layoutHelpers/HeaderBreadcrumbs";

import { Link as RouterLink } from "react-router-dom";
import { MdPersonAdd } from "react-icons/md";
import { useEffect} from "react";
import { useAuthContext } from "../../context/auth/authContext";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import usePermissions from "../../hooks/usePermssions";

const UsersList = () => {
  const axiosPrivate = useAxiosPrivate();
  const { users, setStateUsers } = useAuthContext();
  const USERS_URL = "/users";
  const permissons = usePermissions("user");



  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate(USERS_URL, {
          signal: controller.signal,
        });

        if (response.data.code === 200 && isMounted) {
          setStateUsers(response.data.data);
        } else {
          isMounted && setStateUsers([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <GridPageWrapper>
      <Grid item xs={12}>
        {permissons.all || permissons.create ? (
          <HeaderBreadcrumbs
            heading='User List'
            links={[{ name: "Dashboard", href: "/" }, { name: "Users List" }]}
            action={
              <Button
                variant='contained'
                component={RouterLink}
                to={"/users/add-user"}
                startIcon={<MdPersonAdd />}
              >
                New User
              </Button>
            }
          />
        ) : (
          <HeaderBreadcrumbs
            heading='User List'
            links={[{ name: "Dashboard", href: "/" }, { name: "Users List" }]}
          />
        )}

        {users ? (
          <SingleUserList users={users} userPermissions={permissons} />
        ) : (
          <Skeleton width='100%' height='700px' sx={{ marginTop: "-120px" }} />
        )}
      </Grid>
    </GridPageWrapper>
  );
};
export default UsersList;
