// import { sentenceCase } from "change-case";
import { useState } from "react";

// material

import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
} from "@mui/material";

import ListHead from "../../helpers/ListHead";
import SearchNotFound from "../../helpers/SearchNotFound";
import ListCtaMenu from "../../helpers/ListCtaMenu";


const TABLE_HEAD = [
  { id: "name", label: "Group Name", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function SingleGroupList({ groups }) {
  const [filterName, setFilterName] = useState("");

  const isUserNotFound = groups.length === 0;

  const groupPermissions = {
    edit: true,
    delete: true,
  };


  return (
    <Card className='reservationTableWrapper'>
      <TableContainer>
        <Table>
          <ListHead headLabel={TABLE_HEAD} rowCount={groups.length} />
          <TableBody>
            {groups.map((row) => {
              const { id, name } = row;

              return (
                <TableRow hover key={id} tabIndex={-1} role='checkbox'>
                  <TableCell component='th' scope='row'>
                    <Stack direction='row' alignItems='center' spacing={2}>
                      <Typography variant='subtitle2' noWrap>
                        {name}
                      </Typography>
                    </Stack>
                  </TableCell>

                  <TableCell align='right'>
                    <ListCtaMenu id={id} permissions={groupPermissions} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          {isUserNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Card>
  );
}
