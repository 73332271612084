import {
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Fab,
  Box,
} from "@mui/material";
import ReservationBodyWrapper from "./styles";
import moment from "moment";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SubservicesList from "../../../components/dashboard/booking/SubservicesList";
import { useBookingContext } from "../../../context/booking/bookingContext";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CurrentMonthCalendar from "../../../components/dashboard/booking/CurrentMonthCalendar";
import Scheme from "../../../components/dashboard/booking/Scheme";
import {useAuthContext} from "../../../context/auth/authContext";
import {useTimesShiftsConversion} from "../../../hooks/useTimesShiftConversion";
const ReservationBody = ({
  selectedService,
  orderDate,
  reservationIdentification,
  isReservationCurrentlyEdited,
}) => {
    const {
      shifts,
      currentlyEditedReservation,
      setPrevReservationTimeRange,
      setActiveReservationColor,
      setCurrentlyEditedReservation,
      reservationStoped,
      selectedShift,
      setInitalTimeDurations,
      availabilityRange,
      setDynamicVariableForItemsInOrderChange,
      dynamicVar
    } = useBookingContext();
  const {handleActiveReservationListChange} = useAuthContext();
  const axiosPrivate = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState("list");
  const {times, shiftLength} = useTimesShiftsConversion()


  useEffect(() => {
    let isMounted = true;

    const getAvailability = async () => {
      let viewType = 3;
      switch (currentView) {
        case "list":
          viewType = 1;
          break;
        case "scheme":
          viewType = 2;
          break;
        case "calendar":
          viewType = 3;
          break;
        default:
          viewType = 3;
          break;
      }
      let url = `/order/availability?service=${selectedService.id}&date=${orderDate}&type=${viewType}`;
      try {

        const response = await axiosPrivate(url);


        if (response.data.code === 200 && isMounted) {
          setPrevReservationTimeRange({
            name:viewType,
            ...response.data.data
          } || {});

        }
      } catch (error) {
        console.log(error);
      }

    };

    getAvailability();

    return () => {
      isMounted = false;

    };
  }, [
    selectedService.id,
    orderDate,
    currentView,
    reservationIdentification,
    reservationStoped,
    dynamicVar
  ]);




  const handleFabClick = async () => {

    try {
      if (!isReservationCurrentlyEdited) {
        const response = await axiosPrivate.put(
          `/order`,
          currentlyEditedReservation
        );
        if (response?.data.code === 200) {
          setInitalTimeDurations();
          setCurrentlyEditedReservation(response.data.data.order);
          setActiveReservationColor(response.data.data.order.id);
          handleActiveReservationListChange(response.data.data.order.id);
          navigate(`/create-reservation/edit/${response.data.data.order.id}`);
          enqueueSnackbar(
            "Reservation created successfully!",

            {
              variant: "success",
            }
          );
          if(currentView === "scheme") {
            setCurrentView("list")
          }
        } else if(response?.data.code === 350)  {
          setInitalTimeDurations();
          setDynamicVariableForItemsInOrderChange(moment(new Date).format("X"))
          setCurrentlyEditedReservation(response.data.data.order);
          enqueueSnackbar(
              `${response.data.message}`,

              {
                variant: "error",
              }
          );
        } else {
          enqueueSnackbar(
            `${response.data.message}`,

            {
              variant: "error",
            }
          );
        }
      } else {
        const response = await axiosPrivate.put(
          `/order/${currentlyEditedReservation.id}`,
          currentlyEditedReservation
        );

        if (response?.data.code === 200) {
          setInitalTimeDurations();
          setDynamicVariableForItemsInOrderChange(moment(new Date).format("X"))
          setCurrentlyEditedReservation(response.data.data.order);
          enqueueSnackbar(
            "Reservation updated successfully!",

            {
              variant: "success",
            }
          );
          if(currentView === "scheme") {
            setCurrentView("list")
          }
        } else if(response?.data.code === 350)  {
          setInitalTimeDurations();
          setDynamicVariableForItemsInOrderChange(moment(new Date).format("X"))
          setCurrentlyEditedReservation(response.data.data.order);
          enqueueSnackbar(
              `${response.data.message}`,

              {
                variant: "error",
              }
          );
        } else {

          enqueueSnackbar(
              `${response.data.message}`,

              {
                variant: "error",
              }
          );
        }
      }
    } catch (error) {
      console.error(error);
    }

  }

  const dateText =  moment(orderDate).format("DD.MM.YYYY");
  const selectedShiftValue = shifts.find((shift) => shift.id === selectedShift);

  const handleViewChange = (e, newVal) => {
      setCurrentView(newVal);

  };

  const VIEWS = [
    { id: 1, name: "list", value: "list" },
    { id: 2, name: "scheme", value: "scheme" },
    { id: 3, name: "calendar", value: "calendar" },
  ];


  return (
    <ReservationBodyWrapper>
      <div className='title-bar'>
        <ul>
          <li>{dateText} </li>
          <li>{selectedShiftValue?.name}</li>
          <li>{selectedService?.name}</li>
        </ul>

        <ToggleButtonGroup
          color='primary'
          exclusive
          onChange={handleViewChange}
          value={currentView}
        >
          {VIEWS.map((view) => {
            return (
              <ToggleButton key={view.id} value={view.value} name={view.name} disabled={currentView === view.value}>
                {view.name}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
      <div className='description-box'>{selectedService?.description}</div>
      {times.length > 0 && availabilityRange &&
        <>
          {currentView === "calendar" &&  (
              <CurrentMonthCalendar setCurrentView={setCurrentView} />
          )}
          {currentView === "list" && (
              <SubservicesList times={times} shiftLength={shiftLength} orderDate={orderDate} dynamicVar={dynamicVar} />
          )}

          {currentView === "scheme" && (
              <Scheme setCurrentView={setCurrentView} times={times} handleFabClick={handleFabClick}/>
          )}

          </>
      }


      <Box className='floatingButtonWrapper'>
        {!isReservationCurrentlyEdited ? (
          <Button variant='contained' onClick={handleFabClick}>
            Create new Reservation
          </Button>
        ) : (
          <Fab color='primary' aria-label='add' onClick={handleFabClick}>
            <AddIcon />
          </Fab>
        )}
      </Box>
    </ReservationBodyWrapper>
  );
};
export default ReservationBody;
