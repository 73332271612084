import React from "react";
import ReactDOM from "react-dom";
import "simplebar/dist/simplebar.min.css";
import "./index.css";
import App from "./App";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { AuthProvider } from "./context/auth/authContext";
import { BookingProvider } from "./context/booking/bookingContext";
import NotistackProvider from "./components/helpers/NotisackProvider";
import { SettingsProvider } from "./context/settings/SettingsContext";
import {UtilityProvider} from "./context/utility/utilityContext";


if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
}
ReactDOM.render(
  <AuthProvider>
    <SettingsProvider>
        <UtilityProvider>
        <BookingProvider>
          <NotistackProvider>
             <App />
          </NotistackProvider>
        </BookingProvider>
        </UtilityProvider>
    </SettingsProvider>
  </AuthProvider>,
  document.getElementById("root")
);
