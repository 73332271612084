import { Grid } from "@mui/material";
import GridPageWrapper from "../../assets/wrappers/GridPageWrapper";
import HeaderBreadcrumbs from "../../components/layoutHelpers/HeaderBreadcrumbs";
import { useBookingContext } from "../../context/booking/bookingContext";

import { useParams, useLocation } from "react-router-dom";
import ShiftNewForm from "../../components/dashboard/services/ShiftNewForm";
import { useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const AddShift = () => {
  const { shifts, setStateShifts } = useBookingContext();
  const { pathname } = useLocation();
  const { id } = useParams();
  const isEdit = pathname.includes("edit");
  const currentShift = shifts.find((shift) => shift.id === id);
  const axiosPrivate = useAxiosPrivate();

  const SHIFTS_URL = "/shifts";

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getShifts = async () => {
      try {
        const response = await axiosPrivate(SHIFTS_URL, {
          signal: controller.signal,
        });

        if (response.data.code === 200 && isMounted) {
          setStateShifts(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getShifts();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <GridPageWrapper>
      <Grid item xs={12}>
        <HeaderBreadcrumbs
          heading={isEdit ? `Edit Shift - ${currentShift?.name}` : "Add Shift"}
          links={[
            { name: "Dashboard", href: "/" },
            { name: "Shifts List", href: "/shifts" },
            { name: !isEdit ? "New Shift" : "Edit Shift" },
          ]}
        />
      </Grid>

      <ShiftNewForm
        isEdit={isEdit}
        currentShift={currentShift}
        shifts={shifts}
      />
    </GridPageWrapper>
  );
};
export default AddShift;
