import { Box, Button, Grid, Icon, Skeleton } from "@mui/material";
import GridPageWrapper from "../../assets/wrappers/GridPageWrapper";
import HeaderBreadcrumbs from "../../components/layoutHelpers/HeaderBreadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { MdAvTimer } from "react-icons/md";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth/authContext";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useBookingContext } from "../../context/booking/bookingContext";
import SingleShiftList from "../../components/dashboard/services/SingleShiftList";
import usePermissions from "../../hooks/usePermssions";

const ShiftsPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const { shifts, setStateShifts } = useBookingContext();
  const { user } = useAuthContext();
  const permissons = usePermissions("shift");
  const SHIFTS_URL = "/shifts";

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getShifts = async () => {
      try {
        const response = await axiosPrivate(SHIFTS_URL, {
          signal: controller.signal,
        });

        if (response.data.code === 200 && isMounted) {
          setStateShifts(response.data.data);
        } else {
          isMounted && setStateShifts([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getShifts();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [user.franchise]);

  return (
    <GridPageWrapper>
      <Grid item xs={12}>
        {permissons.all || permissons.create ? (
          <HeaderBreadcrumbs
            heading='Working Hours'
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Working Hours" },
            ]}
            action={
              <Button
                variant='contained'
                component={RouterLink}
                to={"/shifts/add-shift"}
                startIcon={<MdAvTimer />}
              >
                New Shift
              </Button>
            }
          />
        ) : (
          <HeaderBreadcrumbs
            heading='Working Hours'
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Working Hours" },
            ]}
          />
        )}

        {shifts ? (
          <SingleShiftList shifts={shifts} shiftPermissions={permissons} />
        ) : (
          <Skeleton width='100%' height='700px' sx={{ marginTop: "-120px" }} />
        )}
      </Grid>
    </GridPageWrapper>
  );
};
export default ShiftsPage;
