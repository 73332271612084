import React, { useReducer, useContext } from "react";

import {
  ADD_ACTIVE_SUB_SERVICE,
  ADD_SERVICE,
  ADD_SHIFT,
  DELETE_ACTIVE_SUB_SERVICE,
  DELETE_SELECTED_TIMEDURATIONS,
  DELETE_SERVICE,
  DELETE_SHIFT,
  GET_ORDERS,
  GET_SERVICES,
  GET_SHIFTS,
  GET_SUB_SERVICES,
  HANDLE_ACTIVE_RESERVATION_CHANGE,
  HANDLE_ACTIVE_RESERVATION_ITEMS_CHANGE,
  HANDLE_ACTIVE_RESERVATION_ITEMS_DELETE,
  SET_GLOBAL_SEARCH,
  SET_INITAL_TIMEDURATIONS,
  SET_ORDER_DATE,
  SET_PREV_TIME_RANGE,
  SET_RESERVATION,
  SET_RESERVATION_COLOR,
  SET_RESERVATION_STOP,
  SET_SELECTED_SERVICE,
  SET_SELECTED_SHIFT,
  SET_SELECTED_TIMEDURATIONS,
  SET_IS_LOADING_STATE,
  SET_OPENED_POPOVER_RESRVATIONS,
  SET_ALL_SELECTED_TIMEDURATIONS,
  HANDLE_SELECT_ALL_ACTIVE_RESERVATION_ITEMS_CHANGE,
  SET_DYNAMIC_VAR,
  SET_SEARCH_TERM,
  UPDATE_ORDERS, SET_ARRIVED_FILLTER,

} from "../actions";

import bookingReducer from "./bookingReducer";
import moment from "moment";


const initialState = {
  isGlobalSearchOpen: false,
  searchTerm:"",
  orderDate: moment(new Date).format("YYYY-MM-DD"),
  services: [],
  subservices: [],
  shifts: [],
  activeSubServices: [],
  currentlyEditedReservation: {
    status: 1,
    items: [],
  },
  availabilityRange: null,
  orders: [],
  activeReservationColor: "",
  selectedService: null,
  selectedShift: null,
  reservationStoped: "",
  selectedTimeDurations: [],
  isLoadingState:true,
  openedPopoverReservation: null,
  dynamicVar:parseInt(moment(new Date).format("X")),


};

const BookingContext = React.createContext();

const BookingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(bookingReducer, initialState);


  const setStateOrders = (orders) => {
    dispatch({
      type: GET_ORDERS,
      payload: { orders },
    });
  };

  const updateStateOrders = (order) => {

    dispatch({
      type: UPDATE_ORDERS,
      payload: { order },
    });
  };
  const setSearchTerm = (term) => {
    dispatch({
      type: SET_SEARCH_TERM,
      payload: { term },
    });
  };

  const setDynamicVariableForItemsInOrderChange = (value) => {
    dispatch({
      type: SET_DYNAMIC_VAR,
      payload: { value },
    });
  };


  const setIsloadingState = () => {
    dispatch({
      type: SET_IS_LOADING_STATE,
    });
  };

  const setOrderDate = (date) => {
    dispatch({
      type: SET_ORDER_DATE,
      payload: { date },
    });
  };

  const setOpenedPopoverReservation = (resId) => {
    dispatch({
      type: SET_OPENED_POPOVER_RESRVATIONS,
      payload: { resId },
    });
  };
  const setInitalTimeDurations = () => {
    dispatch({
      type: SET_INITAL_TIMEDURATIONS,
    });
  };

  const setSelectedTimeDurations = (timeDuration) => {
    setIsloadingState()
    dispatch({
      type: SET_SELECTED_TIMEDURATIONS,
      payload: { timeDuration },
    });
  };

  const setAllSelectedTimeDurations = (timeDurations) => {
    setIsloadingState()
    dispatch({
      type: SET_ALL_SELECTED_TIMEDURATIONS,
      payload: { timeDurations },
    });
  };

  const setAllSelectedItemsInCurrentlyEditedRes = (newItems) => {
    setIsloadingState()
    dispatch({
      type: HANDLE_SELECT_ALL_ACTIVE_RESERVATION_ITEMS_CHANGE,
      payload: { newItems },
    });
  };

  const deleteSelectedTimeDuration = (timeDurationId) => {
    dispatch({
      type: DELETE_SELECTED_TIMEDURATIONS,
      payload: { timeDurationId },
    });
  };

  const setReservationStop = (stopId) => {
    dispatch({
      type: SET_RESERVATION_STOP,
      payload: { stopId },
    });
  };

  const setSelectedService = (service) => {
    dispatch({
      type: SET_SELECTED_SERVICE,
      payload: { service },
    });
  };
  const setSelectedShift = (shift) => {
    dispatch({
      type: SET_SELECTED_SHIFT,
      payload: { shift },
    });
  };

  const setStateServices = (services) => {
    dispatch({
      type: GET_SERVICES,
      payload: { services },
    });
  };

  const setStateSubServices = (subservices) => {
    dispatch({
      type: GET_SUB_SERVICES,
      payload: { subservices },
    });
  };

  const setStateShifts = (shifts) => {
    dispatch({
      type: GET_SHIFTS,
      payload: { shifts },
    });
  };

  const addStateService = (service) => {
    dispatch({
      type: ADD_SERVICE,
      payload: { service },
    });
  };

  const addStateShift = (shift) => {
    dispatch({
      type: ADD_SHIFT,
      payload: { shift },
    });
  };
  const deleteStateService = (serviceId) => {
    dispatch({
      type: DELETE_SERVICE,
      payload: { serviceId },
    });
  };

  const deleteStateShift = (shiftId) => {
    dispatch({
      type: DELETE_SHIFT,
      payload: { shiftId },
    });
  };

  const setIsGlobalSearchOpen = (val) => {
    dispatch({
      type: SET_GLOBAL_SEARCH,
      payload: { val },
    });
  };

  const addActiveSubservices = (subserviceId) => {
    dispatch({
      type: ADD_ACTIVE_SUB_SERVICE,
      payload: { subserviceId },
    });
  };

  const deleteActiveSubservices = (subserviceId) => {
    dispatch({
      type: DELETE_ACTIVE_SUB_SERVICE,
      payload: { subserviceId },
    });
  };



  const setCurrentlyEditedReservation = (reservation) => {
    dispatch({
      type: SET_RESERVATION,
      payload: { reservation },
    });
  };

  const handleActiveReservationChange = ({ name, value }) => {
    dispatch({
      type: HANDLE_ACTIVE_RESERVATION_CHANGE,
      payload: { name, value },
    });
  };

  const handleActiveReservationItemsChange = ({ value }) => {
    dispatch({
      type: HANDLE_ACTIVE_RESERVATION_ITEMS_CHANGE,
      payload: { value },
    });
  };

  const handleActiveReservationItemsDelete = (itemId) => {
    dispatch({
      type: HANDLE_ACTIVE_RESERVATION_ITEMS_DELETE,
      payload: { itemId },
    });
  };
  const setPrevReservationTimeRange = (value) => {
    dispatch({
      type: SET_PREV_TIME_RANGE,
      payload: { value },
    });
  };

  const setActiveReservationColor = (value) => {
    dispatch({
      type: SET_RESERVATION_COLOR,
      payload: { value },
    });
  };


  return (
    <BookingContext.Provider
      value={{
        ...state,
        setStateOrders,
        setStateServices,
        setStateShifts,
        addStateService,
        deleteStateService,
        addStateShift,
        deleteStateShift,
        setIsGlobalSearchOpen,
        setStateSubServices,
        addActiveSubservices,
        deleteActiveSubservices,
        handleActiveReservationChange,
        setCurrentlyEditedReservation,
        setPrevReservationTimeRange,
        handleActiveReservationItemsChange,
        handleActiveReservationItemsDelete,
        setActiveReservationColor,
        setOrderDate,
        setSelectedService,
        setSelectedShift,
        setReservationStop,
        setSelectedTimeDurations,
        setInitalTimeDurations,
        deleteSelectedTimeDuration,
        setOpenedPopoverReservation,
        setAllSelectedTimeDurations,
        setAllSelectedItemsInCurrentlyEditedRes,
        setDynamicVariableForItemsInOrderChange,
        setSearchTerm,
        updateStateOrders,


      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

const useBookingContext = () => {
  return useContext(BookingContext);
};

export { BookingProvider, initialState, useBookingContext };
