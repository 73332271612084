import { axiosPrivate } from "../api/axios";
import { useAuthContext } from "../context/auth/authContext";

const useRefreshToken = () => {
  const {
    regenerateTokens,
    refreshToken,
    accessToken,
    user,
  } = useAuthContext();

  const refresh = async () => {
    const response = await axiosPrivate.post(
      "/regenerate_token",
      JSON.stringify({ refresh_token: refreshToken }),

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Userid: `${user.id}`,
        },
      }
    );

    regenerateTokens(
      response?.data?.data?.session?.access_token,
      response?.data?.data?.session?.refresh_token
    );

    const newTokens = {
      accessToken: response?.data?.data?.session?.access_token,
      refreshToken: response?.data?.data?.session?.refresh_token,
    };

    return newTokens;
  };

  return refresh;
};
export default useRefreshToken;
