import React, { useEffect, useState } from "react";
import { useBookingContext } from "../../../context/booking/bookingContext";
import moment from "moment";
import { v4 as uuid } from "uuid";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Skeleton,
  TextField,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { useContainerDimensions } from "../../../hooks/useContainerDimensions";
import ConfirmDeletePopover from "../../helpers/ConfirmDeletePopover";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/auth/authContext";
import SwitchTimeRange from "./SingleSubserviceHelpers/SwitchTimeRange";
import { useSnackbar } from "notistack";
import { CalculateOutlined } from "@mui/icons-material";

const SingleSubService = React.forwardRef((props, ref) => {
  const {
    id,
    vat_rate,
    name,
    times,
    price: props_price,
    shiftLength,
    selectedService,
    orderDate,
    subserviceId,
    min_persons,
    qty,
  } = props;

  const axiosPrivate = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    addActiveSubservices,
    deleteActiveSubservices,
    currentlyEditedReservation,
    handleActiveReservationItemsChange,
    handleActiveReservationItemsDelete,
    setCurrentlyEditedReservation,
    availabilityRange,
    selectedTimeDurations,
    setSelectedTimeDurations,
    setInitalTimeDurations,
    deleteSelectedTimeDuration,
    setReservationStop,
    setOpenedPopoverReservation,
    setActiveReservationColor,
    dynamicVar,
    subservices,
  } = useBookingContext();

  const { handleActiveReservationListChange } = useAuthContext();

  const [isSelected, setIsSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [timeFrom, setTimeFrom] = useState(times[0] || "");
  const [timeTo, setTimeTo] = useState(times[times.length - 1] || "");
  const [price, setPrice] = useState(props_price);
  const [vatRate, setVatRate] = useState(vat_rate);
  const [brutoPrice, setBrutoPrice] = useState("0");
  const [persons, setPersons] = useState(min_persons);
  const [activeTimeRange, setActiveTimeRange] = useState("");
  const { width } = useContainerDimensions(ref);
  const pxValue = width / shiftLength;
  const navigate = useNavigate();

  useEffect(() => {
    setInitalTimeDurations();
  }, [dynamicVar, orderDate]);

  useEffect(() => {
    let isMounted = true;
    const timeRanges =
      availabilityRange?.name === 1 ? availabilityRange[id] : undefined;
    timeRanges?.forEach((range) => {
      let startTimeStamp = moment(range.time_from).format("X");
      let endTimeStamp = moment(range.time_to).format("X");
      let initialStartTimeStamp = moment(times[0]).format("X");
      let offset = (startTimeStamp - initialStartTimeStamp) / 60;
      let duration = (endTimeStamp - startTimeStamp) / 60;

      const offsetPrecentage = (offset / width) * 100 * pxValue;
      const durationPrecentage = (duration / width) * 100 * pxValue;

      console.log("RANGE ---->", range);

      if (isMounted && width) {
        setSelectedTimeDurations({
          id: range.id,
          order: range.order,
          order_status: range.order_status,
          subservice: range.subservice,
          date: orderDate,
          time_from: range.time_from,
          time_to: range.time_to,
          name: range.name,
          offsetPrecentage,
          durationPrecentage,
          isEditable: range.order === currentlyEditedReservation.id,
          order_names: range.order_names,
          unit_price: range.unit_price,
          vat_rate: range.vat_rate,
          service_type_id: range.service_type_id,
          no_persons: range.no_persons,
          bruto: range.bruto,
        });
      }
    });

    setTimeFrom(times[0] || "");
    setTimeTo(times[times.length - 1] || "");
  }, [availabilityRange, orderDate, ref.current]);

  const handleCalculate = () => {
    const newPrice = brutoPrice / (1 + parseInt(vatRate) / 100);
    setPrice(newPrice.toFixed(6));
  };

  const handleTimeFromChange = (event) => {
    setTimeFrom(event.target.value || "");
  };

  const handleTimeToChange = (event) => {
    setTimeTo(event.target.value || "");
  };

  const handleClickOpen = (subserviceid) => {
    if (!isSelected) {
      addActiveSubservices(subserviceid);
      setIsSelected(true);
      setPrice(props_price);
      setBrutoPrice("0");
      setPersons(min_persons);
      setVatRate(vat_rate);
    }
    setOpen(true);
  };

  const handleClickItem = (subserviceid, item) => {
    if (!isSelected) {
      addActiveSubservices(subserviceid);
      setIsSelected(true);
    }
    const timeFromIndex = times.findIndex(
      (index) =>
        moment(index).format("X") === moment(item.time_from).format("X"),
    );
    const timeToIndex = times.findIndex(
      (index) => moment(index).format("X") === moment(item.time_to).format("X"),
    );
    setTimeFrom(times[timeFromIndex]);
    setTimeTo(times[timeToIndex]);
    setPrice(item.unit_price);
    setPersons(item.no_persons);
    setVatRate(item.vat_rate);
    setOpen(true);
    setActiveTimeRange(item.id);
  };
  const handleClickOtherReservationItem = async (itemOrder, itemStatus) => {
    if (itemStatus === "4") {
      enqueueSnackbar(
        `This time range belongs to stopped reservation and cannot be edited!`,

        {
          variant: "error",
        },
      );
    } else {
      try {
        const response = await axiosPrivate.patch(`/order/editor/${itemOrder}`);

        if (response?.data.code === 200) {
          if (itemOrder !== currentlyEditedReservation.id) {
            setInitalTimeDurations();
          }

          setCurrentlyEditedReservation(response.data.data);
          handleActiveReservationListChange(itemOrder);
          setActiveReservationColor(response.data.data.id);
          setOpenedPopoverReservation(itemOrder);
          navigate(`/create-reservation/edit/${itemOrder}`);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleClose = (subserviceid, event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
    if (isSelected) {
      deleteActiveSubservices(id);
      setIsSelected(false);
    }
    setActiveTimeRange("");
  };

  const handleSoftDeleteTimeRange = (timeRangeId) => {
    deleteSelectedTimeDuration(timeRangeId);
    handleActiveReservationItemsDelete(timeRangeId);
  };

  const handleDeleteTimeRange = async (timeRangeId) => {
    deleteSelectedTimeDuration(timeRangeId);
    handleActiveReservationItemsDelete(timeRangeId);

    // const newCER= {
    //   ...currentlyEditedReservation,
    //   items: currentlyEditedReservation.items.filter(
    //       (item) => item.id !== timeRangeId)
    // }
    // try {
    //  axiosPrivate.put(
    //       `/order/${currentlyEditedReservation.id}`,
    //       newCER
    //   );
    //  console.log("subservice delete")
    //
    // } catch (e) {
    //   console.log(e)
    // }
  };

  const handleStopTimeRange = async (timeRangeId) => {
    const stopRange = [timeRangeId];

    try {
      const response = await axiosPrivate.patch(
        `/order/stop/${currentlyEditedReservation.id}`,
        stopRange,
      );

      if (response?.data.code === 200) {
        setReservationStop(stopRange);
        setCurrentlyEditedReservation(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTimeFormSubmit = (id, event, reason) => {
    let startTimeStamp = moment(timeFrom).format("X");
    let endTimeStamp = moment(timeTo).format("X");
    let initialStartTimeStamp = moment(times[0]).format("X");

    let offset = (startTimeStamp - initialStartTimeStamp) / 60;
    let duration = (endTimeStamp - startTimeStamp) / 60;

    const offsetPrecentage = (offset / width) * 100 * pxValue;
    const durationPrecentage = (duration / width) * 100 * pxValue;

    //
    // const isPresent = selectedTimeDurations.findIndex(
    //   (obj) => obj.id === activeTimeRange
    // );
    // let itemId = uuid();

    setSelectedTimeDurations({
      id: activeTimeRange || id,
      subservice: id,
      date: orderDate,
      offsetPrecentage,
      durationPrecentage,
      time_from: timeFrom,
      time_to: timeTo,
      isEditable: true,
      stateItem: true,
      unit_price: price,
      no_persons: persons,
    });

    const value = {
      id: activeTimeRange || id,
      subservice: id,
      date: orderDate,
      time_from: timeFrom + ":00",
      time_to: timeTo + ":00",
      state_item: true,
      unit_price: price,
      no_persons: persons,
      bruto: brutoPrice,
    };
    handleActiveReservationItemsChange({ value });

    if (isSelected) {
      deleteActiveSubservices(id);
      setIsSelected(false);
    }
    if (reason !== "backdropClick") {
      setOpen(false);
    }
    setActiveTimeRange("");
  };

  return (
    <div className="single-sub-service" id={subserviceId}>
      <div className={`left ${isSelected ? "active" : ""}`}>
        <Typography
          component="h2"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
            textAlign: "center",
          }}
          className="subservice-title"
        >
          {name}
        </Typography>

        <div className="bottom-action-bar">
          {qty === "0" ? (
            <Typography
              component="p"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                textAlign: "center",
                color: "red",
              }}
            >
              Not Available
            </Typography>
          ) : (
            <Button onClick={() => handleClickOpen(id)}>{"Add"}</Button>
          )}

          <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={() => handleClose(id)}
          >
            <DialogTitle>Select Reservation Duration</DialogTitle>
            <DialogContent>
              <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="time-from">Time From</InputLabel>
                  <Select
                    labelId="time-from"
                    id="dialog-select"
                    value={timeFrom}
                    onChange={handleTimeFromChange}
                    input={<OutlinedInput label="Time From" />}
                  >
                    {times?.map((time) => {
                      return (
                        <MenuItem key={uuid()} value={time}>
                          {time.substr(time.length - 5)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="time-to">Time To</InputLabel>
                  <Select
                    labelId="time-to"
                    id="dialog-select"
                    value={timeTo}
                    onChange={handleTimeToChange}
                    input={<OutlinedInput label="Time To" />}
                  >
                    {times?.map((time) => {
                      return (
                        <MenuItem
                          key={uuid()}
                          value={time}
                          disabled={
                            moment(timeFrom).format("X") >=
                            moment(time).format("X")
                          }
                        >
                          {time.substr(time.length - 5)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {selectedService.price_per_person === "1" && (
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <TextField
                      fullWidth
                      value={persons}
                      type="text"
                      id="persons"
                      label="Number of persons"
                      onChange={(e) => setPersons(e.target.value)}
                    />
                  </FormControl>
                )}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControl sx={{ m: 1, minWidth: 120, pt: 2.5 }}>
                    <TextField
                      fullWidth
                      value={price}
                      type="number"
                      id="price"
                      label="Net Price"
                      onChange={(e) => setPrice(e.target.value)}
                    />
                    <FormHelperText>
                      Price entered in the net amount
                    </FormHelperText>
                  </FormControl>

                  <IconButton onClick={handleCalculate} sx={{ m: 1 }}>
                    <CalculateOutlined />
                  </IconButton>

                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <TextField
                      fullWidth
                      value={brutoPrice}
                      type="number"
                      id="bruto-price"
                      label="Bruto Price"
                      onChange={(e) => setBrutoPrice(e.target.value)}
                    />
                  </FormControl>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={() => handleTimeFormSubmit(id)}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <div className="right" ref={ref}>
        {selectedTimeDurations.length >= 0 ? (
          selectedTimeDurations?.map((item, i) => {
            const timeDiff =
              moment(item.time_to).format("X") -
              moment(item.time_from).format("X");
            if (item.subservice === id) {
              return (
                <div
                  key={i}
                  style={{
                    left: `${item.offsetPrecentage}%`,
                    width: `${item.durationPrecentage}%`,
                  }}
                  className={
                    item.isEditable
                      ? "editable time-range-div"
                      : "time-range-div"
                  }
                  id={item.subservice}
                >
                  <div className="switch-box">
                    <SwitchTimeRange
                      subservices={subservices[selectedService?.id]}
                      handleSoftDeleteTimeRange={() =>
                        handleSoftDeleteTimeRange(item.id)
                      }
                      timeRange={item}
                      setSelectedTimeDurations={setSelectedTimeDurations}
                      handleActiveReservationItemsChange={
                        handleActiveReservationItemsChange
                      }
                      selectedTimeDurations={selectedTimeDurations}
                    />
                  </div>

                  <div
                    className="inner"
                    onClick={
                      item.isEditable
                        ? () => handleClickItem(id, item)
                        : () =>
                            handleClickOtherReservationItem(
                              item.order,
                              item.order_status,
                            )
                    }
                  >
                    <p>
                      {timeDiff >= 9000 && (
                        <>
                          From
                          <span>
                            {moment(item.time_from).format("LT") || "start"}
                          </span>
                          to
                          <span>
                            {moment(item.time_to).format("LT") || "shift end"}
                          </span>
                          {item.bruto && (
                            <>
                              | Price:
                              <span>€ {parseInt(item.bruto).toFixed(2)}</span>
                            </>
                          )}
                          {item.no_persons && (
                            <>
                              | Persons:
                              <span>{item.no_persons}</span>
                            </>
                          )}
                        </>
                      )}
                    </p>
                    {!item.stateItem && (
                      <p>
                        Order:
                        <span>{item.order}</span> Client:
                        <span>{item.order_names}</span>
                      </p>
                    )}
                  </div>

                  <div className="delete-box">
                    <ConfirmDeletePopover
                      onDelete={() => handleDeleteTimeRange(item.id)}
                    />
                  </div>
                </div>
              );
            }
          })
        ) : (
          <Skeleton
            width="100%"
            height="120px"
            sx={{
              position: "absolute",
              marginTop: "-27px",
              top: "0",
              borderRadius: "4px",
            }}
          />
        )}
      </div>
    </div>
  );
});
export default SingleSubService;
