import axios from "axios";
//carpediembooking.elatus.dev je devlopment/sandobox
//carpediem.elatus.dev je production

//NOVI PRODUCTION -> www.booking.cdhvar.com

export const isProd = true;

let URL;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  URL = "/en";
} else {
  if (isProd) {
    URL = process.env.REACT_APP_PRODUCTION_API;
  } else {
    URL = process.env.REACT_APP_DEV_API;
  }
}

export default axios.create({
  baseURL: URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

export const axiosPrivate = axios.create({
  baseURL: URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});
