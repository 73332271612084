import styled from "styled-components";
import moment from "moment";
const currentMonthClass = moment(new Date()).format("YYYY-MM").toString();


const ActiveReservationWrapper = styled.div`
  width: 100%;
  padding: 0 25px;
  position: relative;

  .author-box {
    .form-row {
      margin-bottom: 10px;
    }

    .countryCodeRow {
      display: flex;
      align-items: flex-end;
      .countryCodeBtn {
        cursor: pointer;
        width: 60px;
        height: 90%;
        text-align: center;
        padding: 8px 5px;
        background: lightgray;
        font-size: 16px;
        
        input {
          background: lightgray;
          border: none;
          width: 50px;
          text-align: center;
          font-size: 16px;
          &.emptyInput {
            width: 0;
            height: 0;
           
          }
        }
        
        svg {
          width: .7em;
          height: .7em;
          margin-right: 7px;
        }
      }
    }
    .form-label {
      display: block;
      font-size: 12px;
      text-transform: uppercase;
    }
    .form-input {
      width: 100%;
      height: 35px;
      border: none;
      border-bottom: 1px solid grey;
      font-size: 16px;

      &:focus {
        outline: none;
        border: none;
        border-bottom: 1px solid green;
      }
    }
    .form-textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid grey;
      font-size: 16px;
      min-height: 75px;
      &:focus {
        outline: none;
        border: none;
        border-bottom: 1px solid green;
      }
    }
  }

  .services-box {
    .single-service {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
        padding: 0;
        color: #2e7d32;
        font-size: 16px;
        font-weight: bold;
        span {
          display: block;
          font-size: 11px;
        }
      }
    }
  }
  .icons { 
    display:flex;
    align-items:center;
    
  }
  
  .totalWrap {
    p {
      font-weight: bold;
      color: #2e7d32;
    }
  }

`;

export const BottomPopoverCta = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`

export const CalendarWrapper = styled.div`
  .customEventClass {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }
    .fc-event-title {
      color: #fff;
      text-align: center;
      font-weight: bold;
    }
  }
  
  .fc-day[data-date^="${currentMonthClass}"] {
    background:#4BB543;
  }
 
`;

export const TableFiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  @media (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
  }
  div.inner-wrapper {
   display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    //@media (max-width: 1150px) {
    //  margin-bottom: 20px;
    //}
    @media (max-width: 768px) {
      justify-content: flex-start;
      flex-wrap: wrap;
      height: auto;
      margin: 0;
    }
    
    &.inner-wrapper-alt {
      justify-content: flex-start;

      .MuiBox-root {
        margin: 0 5px;
        min-width: 127.5px;


        @media (max-width: 420px) {
          margin: 0 5px;
          width: auto;
          flex: 1;

        }
      }
    }
  }

  
  .statusSelect,.createdBySelect, .currentEditorSelect, .serviceSelect, .yearDatepicker, .arrivedSelect, .approvedSelect, .sortBySelect,.paidSelect, .sourceSelect {
    margin: 0 5px;
    width: 100%;

    @media (max-width: 768px) {
      margin: 5px !important;
      width: auto !important;
     flex: 1 !important;
  
    }
  }
  .datepicker-box {
    margin: 0 5px;
    width: 100%;
    
    @media (max-width: 768px) {
  
      margin: 5px;
      width: auto;
      flex: 1;
    }
  }
`


export const MailModalWrapper = styled.div`
  padding: 15px 0;
  width: 100%;
  min-width: 550px;
  overflow-x: scroll;
  @media (min-width: 575px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  `

export const ModalListWrapper = styled.div`
  padding: 15px 0;
  width: 100%;
  min-width: 700px;
  overflow-x: scroll;
  @media (min-width: 575px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  dl.infoDl {
    margin: 0;
    padding: 0;
    
    div {
      display: flex;
      align-items: flex-start;
      padding: 5px 0;
      border-bottom: 1px solid #D8D8D8;
      dt {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.2rem;
        min-width: 100px;
        @media (max-width: 768px) {
          font-size: 1rem;
          min-width:75px;
        }
      }
      dd {
        margin: 0;
        color:black;
        
        a {
          color:black;
        }
      }
    }
 

  }  
  
  dl.statusDl {
    margin: 0;
    padding: 25px 0;
    display: flex;
    flex-wrap: wrap;
    
    div {
      display: flex;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #D8D8D8;
      width: 50%;
      dt {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.2rem;
        min-width: 150px;
        @media (max-width: 768px) {
          font-size: 1rem;
          min-width:115px;
        }
      }
      dd {
        margin: 0;
        color:black;
        padding-left: 5px;
        
        a {
          color:black;
        }
      }
    }
 

  }
  
  .services-wrapper, .logs-wrapper {
     .heading-banner {
       background: #D8D8D8;
       height: auto;
       display: flex;
       justify-content: space-between;
       align-items: center;
       font-size: .8rem;
       text-transform: uppercase;
       padding: 5px 10px;
       
       div {
         span {
           padding-right: 85px;
         }
       }
       .bruto {
         display: block;
         transform: translateX(-225px);
         @media (max-width: 992px) {
           transform: translateX(-210px);
         }
         @media (max-width: 860px) {
           transform: translateX(-180px);
         }
       }
     }
    
    .items-wrapper {
      padding: 10px 0;
      .single-item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #D8D8D8;
        padding: 5px 0;
        div {
          display: flex;
          align-items: center;
          p {
            margin: 0;
          }
        }
        
        .left {
           p { 
             font-weight: bold;
             text-transform: uppercase;
             font-size: 1.2rem;
             min-width: 125px;
             @media (max-width: 768px) {
               font-size: 1rem;
               min-width: 100px;
             }
             
           }
          
        }
        
        .center {
          p{
      
            color:black;
            font-weight: bold;
            span {
              font-weight: 400;
              color:#172b4d;
            }
          }
          p:first-of-type {
            margin-right:25px;
          }
        }
        .right {
    
          p{
            

            color:black;
            font-weight: bold;
            span {
              font-weight: 400;
              color:#172b4d;
            }
          }
        }
        
      }
    }
    
    .total-wrapper {
      text-align: right;
      padding-right: 225px;
      @media (max-width: 992px) {
        padding-right: 210px;
      }
      @media (max-width: 860px) {
        padding-right: 180px;
      }
      p {
        font-weight: bold;
      }
    }
  }

  .logs-wrapper {
    .heading-banner {
      margin-top: 25px;
      div {
        span {
          padding-right: 55px;
        }
        
        &.cta-div {
          span {
            padding-right: 45px;
          }
          span:first-child {
            padding-right: 85px;
          }
        }
      }
    }
    .items-wrapper {
      .single-item {
        cursor: pointer;
        padding: 5px;
        transition: all .3s;
        
        &:hover {
          background: #D8D8D8;
        }
        .right {
          p:last-child {
            min-width: 150px;
            text-align: center;
          }
        }
      }
    }

  }
  
`
export const ReservationModalPopoverWrapper = styled.div`

  width: 100%;
  padding: 20px;
  
  dl {
    margin: 0;
    padding: 0;
    div {
      display: flex;
  
      dt {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.2rem;
        min-width: 125px;
        @media (max-width: 768px) {
          font-size: 1rem;
          min-width: 100px;
        }
      }
      
      dd {
        margin: 0;
        padding: 0;
        inline-size:calc(100% - 125px);
        overflow-wrap: break-word;
        @media (max-width: 768px) {
          inline-size:calc(100% - 100px);
        }
      }
    }
  }
`




export default ActiveReservationWrapper;
