import React, { useEffect,useRef, useState } from 'react';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import * as d3 from "d3-selection";
import {useBookingContext} from "../../../context/booking/bookingContext";
import moment from "moment";
import {useTheme} from "@mui/material/styles";
import ModalDialogPicker from "./SchemeHelpers/ModalDialogPicker";
import SearchClientsInput from "./SchemeHelpers/SearchClientsInput";

const Scheme = ({setCurrentView, times,handleFabClick}) => {
    const { availabilityRange } = useBookingContext();
    const [svg, setSvg] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    const [svgRect, setSvgRect] = useState(null)
    const [modalDialogOpen, setModalDialogOpen] = useState(false);
    const [ordersProp, setOrdersProp] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const [orderDetails, setOrderDetails] = useState({})
    const [clients, setClients] = useState([]);
    const [showClientNames, setShowClientNames] = useState(false)
    const theme = useTheme();
    const ref = useRef(null);
    const {
        selectedService,
        setSelectedTimeDurations,
        handleActiveReservationItemsChange,
        deleteSelectedTimeDuration,
        handleActiveReservationItemsDelete,
        orderDate
    } = useBookingContext();

    const getOrder = async (orderId) => {
        try {
            const response = await axiosPrivate(`/order/${orderId}`);

            if (response.data.code === 200 ) {
                setOrderDetails(response.data.data);
                console.log(response.data.data)


            } else {
                setOrderDetails({});
            }

        } catch (error) {
            console.log(error);
        }
    };

    const handleClickOpen = (orders) => {
        if(orders.length === 1) {
            getOrder(orders[0])
        }
        setModalDialogOpen(true);
    };

    const handleClose = (value) => {
        setModalDialogOpen(false);

    };


    const handleShowClientNames = () => {
        if(!showClientNames) {
            clients?.forEach(({ id, name }) => {
                generateSpan(id,name);
            });
        } else {
            const spans = document.querySelectorAll(".scheme-span");
            spans.forEach(span => {
                span.remove();
            });
        }
        setShowClientNames(!showClientNames);
    };

    let convertedAvailabilityRange;

    let url = selectedService?.image;

    let startingRangeTime;

    const generateSpan = (id, text) => {
        const style = document.createElement('style');
            style.innerHTML = `
        @media only screen and (max-width: 767px) {
          .scheme-span {
            font-size: 10px !important;
            padding: 1px !important;
          }
        }
      `;
        document.head.appendChild(style);

        const span = document.createElement("span");
        const texts = text.split(';').map(item => item.trim());
        const lineBreaks = texts.length > 1 ? `<br/>`.repeat(texts.length - 1) : '';
        span.innerHTML = `${texts.join(lineBreaks)}`;
        span.classList.add("scheme-span");
        span.style.position = "absolute";
        span.style.padding = "3px";
        span.style.border = "1px solid black";
        span.style.background = "white";
        span.style.fontSize = "12px";
        span.style.fontWeight = "bold";
        span.style.transition = "opacity .3s";
        span.style.transform = "rotate(-45deg)";
        span.style.top = `${d3.select(`#subservice-${id}`)?.node()?.getBoundingClientRect().y + window.scrollY - 10}px`;
        span.style.left = `${d3.select(`#subservice-${id}`)?.node()?.getBoundingClientRect().x + window.scrollX}px`;
        span.setAttribute('id', `${id}`);
        document.body.appendChild(span);
    };
    const hideSpan = (id) => {
        const spanElement = document.getElementById(`${id}`);

        if (spanElement) {
            spanElement.style.opacity = "0";
        }
    };

    const showSpan = (id) => {
        const spanElement = document.getElementById(`${id}`);

        if (spanElement) {
            spanElement.style.opacity = '1';
        }
    }

    const searchClientsFunc = (filteredClients, id) => {
        if (filteredClients.some((filteredClient) => filteredClient.id === id)) {
            showSpan(id);
        } else {
            hideSpan(id);
        }
    }
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        let parentDiv;
       const fetchSvg = async () => {
           try {
               const response = await axiosPrivate(url, {
                   signal: controller.signal,
               });


               if ( isMounted) {
                   setSvg(response.data)
                   parentDiv = d3.select(ref.current).select("svg").selectAll(`[id^="subservice"]`)
                   setSvgRect(parentDiv)

               }

           } catch (error) {
               console.log(error);
           }
       }
         fetchSvg();

        return () => {
            isMounted = false;
            controller.abort();
        };

    }, [url, availabilityRange]);




    useEffect(() => {
        if(availabilityRange.name === 2) {

            convertedAvailabilityRange = Object.values(availabilityRange);
            convertedAvailabilityRange?.forEach(range =>{

                if(range.order_names) {
                    setClients((prevState) => ([...prevState.filter(item => item.id !== range.subservice), {id:range.subservice, name:range.order_names}]))
                }
                svgRect?.each(function () {
                    const id = d3.select(this).attr("id").replace(/\D/g, '')
                    const reservePercentage =  (parseInt(range.total_available) - parseInt(range.subservice_reserved)) / parseInt(range.total_available) * 100


                    if(id == range.subservice) {
                        d3.select(`#subservice-${id}`).style("fill", `${range.color}`).style("cursor", "pointer").on("click", function () {
                            if(reservePercentage >= 10) {
                                if (!d3.select(this).classed("selected") ){
                                    d3.select(this).classed("selected", true)
                                    if(range.subservice_reserved === "0") {
                                        startingRangeTime = times[0] + ":00";
                                    } else {
                                        startingRangeTime = moment().format("YYYY-MM-DD HH:mm")
                                    }

                                    d3.select(this).style("fill", "rgb(255, 204, 0)" ).style("stroke", "rgb(255, 204, 0)")
                                    setSelectedTimeDurations({
                                        id:  range.subservice,
                                        subservice: range.subservice,
                                        date: orderDate,
                                        offsetPrecentage: 0,
                                        time_from:startingRangeTime,
                                        time_to:times[times.length - 1] + ":00",
                                        durationPrecentage: 100,
                                        isEditable: true,
                                    });

                                    const value = {
                                        id:  id,
                                        subservice: id,
                                        date: orderDate,
                                        time_from:startingRangeTime,
                                        time_to:times[times.length - 1] + ":00",
                                        state_item:true,
                                    };
                                    handleActiveReservationItemsChange({ value });
                                } else {
                                    d3.select(this).classed("selected", false);
                                    d3.select(this).style("fill", `${range.color}`).style("stroke", "rgb(0, 0, 0)")
                                    deleteSelectedTimeDuration(range.subservice);
                                    handleActiveReservationItemsDelete(range.subservice);
                                }
                            } else  {
                                setOrdersProp(range.orders.split(","))
                                handleClickOpen(range.orders.split(","))
                            }



                        })
                    }
                })

            })
        }

    },[svgRect,availabilityRange])

   useEffect(() => {
       return () => {
           document.querySelectorAll(".scheme-span").forEach(span => span.remove())
           setShowClientNames(false)
       };
   },[selectedService])

    return (
        <>
                <SearchClientsInput handleShowClientNames={handleShowClientNames} showClientNames={showClientNames} clients={clients}  searchClientsFunc={searchClientsFunc}/>
            <div
                className={`svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''}`}
                ref={ref}
                dangerouslySetInnerHTML={{ __html: svg }}
            />
            <ModalDialogPicker open={modalDialogOpen} onClose={handleClose} ordersProp={ordersProp} orderDetails={orderDetails} setOrderDetails={setOrderDetails}/>
        </>

    );
}

export  default Scheme;
