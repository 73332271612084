import { isString } from "lodash";
import PropTypes from "prop-types";
// material
import { Box, Typography, Link } from "@mui/material";
import { MBreadcrumbs } from "../helpers/@material-extend";
import { styled } from "@mui/system";

const HeaderBreadCrumbsFlexBox = styled(
  Box,
  {}
)({
  display: "flex",
  flexDirection: "column",

  ".titleMain": {
    fontSize: "1.5rem",
  },

  ".MuiButton-root": {
    marginTop: "15px",
  },

  "@media (min-width: 575px)": {
    flexDirection: "row",
    alignItems: "center",
    ".MuiButton-root": {
      marginTop: "0",
    },
  },

  "@media (min-width: 992px)": {
    ".titleMain": {
      fontSize: "2rem",
    },
  },
});

//

// ----------------------------------------------------------------------

HeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object,
};

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  moreLink = "" || [],
  sx,
  ...other
}) {
  return (
    <Box sx={{ mb: 5, ...sx }}>
      <HeaderBreadCrumbsFlexBox>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant='h4' gutterBottom className='titleMain'>
            {heading}
          </Typography>
          <MBreadcrumbs links={links} {...other} />
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </HeaderBreadCrumbsFlexBox>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target='_blank' variant='body2'>
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant='body2'
              target='_blank'
              sx={{ display: "table" }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
