import * as React from "react";
import {DebounceInput} from 'react-debounce-input';
import {useBookingContext} from "../../context/booking/bookingContext";
import  {SearchInputWrapper} from "./styles"

import { useNavigate, useLocation } from 'react-router-dom';
import {useEffect} from "react";

const SearchOrdersInput = () => {
  const {setSearchTerm} = useBookingContext()
    const { pathname } = useLocation();
    const navigate = useNavigate();
  const handleChange = (value)=> {
      if (pathname!== "/") {
          navigate("/");
      }
    setSearchTerm(value)
  }

  useEffect(()=> {
      return () => {
          setSearchTerm('');
      };
  },[])

  return (
      <SearchInputWrapper>
        <DebounceInput
            minLength={2}
            className="search"
            placeholder="Search Orders"
            debounceTimeout={500}
            onChange={e => handleChange(e.target.value)} />

      </SearchInputWrapper>

  );
};



export default SearchOrdersInput;
