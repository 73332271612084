import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
const LandingWrapper = styled(
  Grid,
  {}
)({
  height: "100vh",

  ".decoration-image": {
    width: "80%",
    height: "auto",
    margin: "0 auto",
    display: "block",
  },

  ".main-grid-col": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".inner": {
      padding: "15px",
      textAlign: "center",
    },

    ".logo": {
      width: "250px",
      height: "auto",
    },

    ".login-form": {
      maxWidth: "500px",
      margin: "25px auto 0 auto",
      textAlign: "left",
      "@media (min-width: 780px)": {
        maxWidth: "750px",
        margin: "25px auto 100px auto",
      },
      ".MuiFormControlLabel-root": {
        padding: "15px 0",
      },

      ".MuiButton-root": {
        marginTop: "15px",
        color: "white",
      },
    },
  },


});

export default LandingWrapper;
