import PropTypes from "prop-types";
// material
import { Paper, Typography } from "@mui/material";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = "", ...other }) {
  return (
    <Paper {...other} sx={{ padding: "25px" }}>
      <Typography gutterBottom align='center' variant='subtitle1'>
        No Results
      </Typography>
    </Paper>
  );
}
