import { Grid } from "@mui/material";
import GridPageWrapper from "../../assets/wrappers/GridPageWrapper";
import HeaderBreadcrumbs from "../../components/layoutHelpers/HeaderBreadcrumbs";

import UserNewForm from "../../components/dashboard/user/UserNewForm";
import { useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useAuthContext } from "../../context/auth/authContext";
const AddUser = () => {
  const { users, groups, setStateUsers, setStateGroups } = useAuthContext();
  const { pathname } = useLocation();
  const { id } = useParams();
  const isEdit = pathname.includes("edit");
  const currentUser = users.find((user) => user.id === id);
  const axiosPrivate = useAxiosPrivate();

  const USERS_URL = "/users";
  const GROUPS_URL = "/groups";

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsersAndGroups = async () => {
      try {
        axiosPrivate(GROUPS_URL, {
          signal: controller.signal,
        })
          .then((response) => {
            if (response.data.code === 200 && isMounted) {
              setStateGroups(response.data.data);
            }
            return axiosPrivate(USERS_URL, {
              signal: controller.signal,
            });
          })
          .then((response) => {
            if (response.data.code === 200 && isMounted) {
              setStateUsers(response.data.data);
            }
          });
      } catch (error) {
        console.log(error);
      }
    };

    getUsersAndGroups();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  return (
    <GridPageWrapper>
      <Grid item xs={12}>
        <HeaderBreadcrumbs
          heading={
            isEdit
              ? `Edit User - ${
                  currentUser?.first_name + " " + currentUser?.last_name
                }`
              : "Add User"
          }
          links={[
            { name: "Dashboard", href: "/" },
            { name: "User List", href: "/users" },
            { name: !isEdit ? "New user" : "Edit User" },
          ]}
        />
      </Grid>
      <UserNewForm isEdit={isEdit} currentUser={currentUser} groups={groups} />
    </GridPageWrapper>
  );
};
export default AddUser;
