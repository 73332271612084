import { styled } from "@mui/system";
import { Toolbar } from "@mui/material";
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: "space-between",
  paddingTop: "10px",
  paddingBottom: "10px",
  position: "relative",

  ".homeIcon": {
    marginRight: "auto",
  },

  ".search-autocomplete": {
    maxWidth: "1100px",
    flex: 1,
    "@media (min-width: 780px)": {
      margin: "0 25px",
    },

    "& span": {
      "& svg": {
        "& path": {
          color: theme.palette.primary.main,
        },
      },
    },
    label: {
      color: theme.palette.primary.main,
    },

    ".MuiOutlinedInput-root": {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,

      input: {
        height: "100%",
      },

      "&:hover": {
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.main,
        },
      },
    },

    ".MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    ".MuiButtonBase-root": {
      svg: {
        color: theme.palette.primary.main,
      },
    },
  },
  ".drawerIcon": {
    display: "none",
  },

  "@media (min-width: 1200px)": {
    ".drawerIcon": {
      display: "inline-flex",
    },
  },

  // ".MuiButtonBase-root": {
  //   svg: {
  //     color: "#FFF",
  //   },
  // },

}));

export default StyledToolbar;
