import {  Grid, Skeleton } from "@mui/material";
import GridPageWrapper from "../../assets/wrappers/GridPageWrapper";
import HeaderBreadcrumbs from "../../components/layoutHelpers/HeaderBreadcrumbs";
import { useEffect } from "react";
import { useAuthContext } from "../../context/auth/authContext";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SingleGroupList from "../../components/dashboard/user/SingleGroupList";

const GroupPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const { groups, setStateGroups } = useAuthContext();

  const GROUPS_URL = "/groups";

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getGroups = async () => {
      try {
        const response = await axiosPrivate(GROUPS_URL, {
          signal: controller.signal,
        });

        if (response.data.code === 200 && isMounted) {
          setStateGroups(response.data.data || []);
        } else {
          isMounted && setStateGroups([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getGroups();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <GridPageWrapper>
      <Grid item xs={12}>
        {groups ? (
          <HeaderBreadcrumbs
            heading='Group List'
            links={[{ name: "Dashboard", href: "/" }, { name: "Groups List" }]}
          />
        ) : (
          <Skeleton width='100%' height='250px' />
        )}

        {groups ? (
          <SingleGroupList groups={groups} />
        ) : (
          <Skeleton width='100%' height='700px' sx={{ marginTop: "-120px" }} />
        )}
      </Grid>
    </GridPageWrapper>
  );
};
export default GroupPage;
