import { Grid } from "@mui/material";
import GridPageWrapper from "../../assets/wrappers/GridPageWrapper";
import HeaderBreadcrumbs from "../../components/layoutHelpers/HeaderBreadcrumbs";
import AccountForm from "../../components/dashboard/user/AccountForm";

const Account = () => {
  return (
    <GridPageWrapper>
      <Grid item xs={12}>
        <HeaderBreadcrumbs
          heading={"Account profile"}
          links={[{ name: "Dashboard", href: "/" }, { name: "Account" }]}
        />
      </Grid>
      <AccountForm />
    </GridPageWrapper>
  );
};
export default Account;
