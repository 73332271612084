import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";

import { useState } from "react";
import { MdDashboard } from "react-icons/md";
import { MdDateRange } from "react-icons/md";
import { MdEditCalendar } from "react-icons/md";
import { NavLink } from "react-router-dom";

const BottomNavigationNav = () => {
  const [value, setValue] = useState(0);

  return (
    <Paper elevation={3} sx={{ zIndex: 9999 }} className='navigationPaper'>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          component={NavLink}
          to='/'
          label='Dashboard'
          icon={<MdDashboard />}
        />

        <BottomNavigationAction
          component={NavLink}
          to='/create-reservation'
          label='Add New Reservation'
          icon={<MdEditCalendar />}
        />
      </BottomNavigation>
    </Paper>
  );
};
export default BottomNavigationNav;
