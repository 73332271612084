import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { useBookingContext } from "../../../context/booking/bookingContext";
import { CalendarWrapper } from "./styles";

const CurrentMonthCalendar = ({ setCurrentView }) => {
  const { availabilityRange, setOrderDate } = useBookingContext();


  let finalRange = [];

  if (availabilityRange) {
    const convertedAvailabilityRange = Object.values(availabilityRange);

    finalRange = convertedAvailabilityRange.map((item) => {

      let color;

      if (item.percentage <= 50) {
        color = "#4BB543";
      } else if (item.percentage > 50 && item.percentage <= 75) {

        color = "#ffcc00";
      } else if (item.percentage > 75 && item.percentage <= 90) {

        color = "#ff9966";
      } else {

        color = "#cc3300";
      }

      const finalItem = {
        date: item.date,
        title:item.percentage + "%",
        backgroundColor: color,
        borderColor: "white",
        display: "background",
        classNames: "customEventClass",
      };
      return finalItem;
    });
  }

  const handleDateClick = (e) => {
    setOrderDate(e.date.toLocaleString("sv-SE"));
    setCurrentView("list");
  };
  return (
    <CalendarWrapper>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        dateClick={handleDateClick}
        events={[...finalRange]}
      />
    </CalendarWrapper>
  );
};
export default CurrentMonthCalendar;
