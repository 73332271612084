import React, { useReducer, useContext, useEffect } from "react";


import {
    SET_SHOW_EXTRA_FILTERS,
    SET_SHOW_NOT_APPROVED
} from "../actions";

import utilityReducer from "./utilityReducer";


const initialState = {
    showExtraFilters:false,
    showNotApproved:false
};

const UtilityContext = React.createContext();

const UtilityProvider = ({ children }) => {
    const [state, dispatch] = useReducer(utilityReducer, initialState);


    const setShowExtraFilters = (value) => {
        dispatch({
            type: SET_SHOW_EXTRA_FILTERS,
            payload: { value },
        });
    };

    const setShowNotApproved = (value) => {
        dispatch({
            type: SET_SHOW_NOT_APPROVED,
            payload: { value },
        });
    };

    const handleQueryValueChange = (utlitySetQueryValue) => {
        utlitySetQueryValue(prevValues => ({ ...prevValues, approved: '0' }));
    }

    return (
        <UtilityContext.Provider
            value={{
                ...state,
                setShowExtraFilters,
                handleQueryValueChange,
                setShowNotApproved
            }}
        >
            {children}
        </UtilityContext.Provider>
    );
};

const useUtilityContext = () => {
    return useContext(UtilityContext);
};

export { UtilityProvider, initialState, useUtilityContext };
