import { Grid } from "@mui/material";
import GridPageWrapper from "../../assets/wrappers/GridPageWrapper";
import HeaderBreadcrumbs from "../../components/layoutHelpers/HeaderBreadcrumbs";
import { useBookingContext } from "../../context/booking/bookingContext";

import { useParams, useLocation } from "react-router-dom";
import SubServiceForm from "../../components/dashboard/services/SubServiceForm";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const AddSubService = () => {
  const { services, setStateServices } = useBookingContext();
  const { pathname } = useLocation();
  const { id, service} = useParams();
  const isEdit = pathname.includes("edit");
  const axiosPrivate = useAxiosPrivate();
  const currentService = services?.find((serv) => serv.id === service);

  const [currentSubService, setCurrentSubService] = useState();

  const SERVICES_URL = "/services";

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getServicesAndShifts = async () => {
      try {
        axiosPrivate(SERVICES_URL, {
          signal: controller.signal,
        })
          .then((response) => {
            if (response.data.code === 200 && isMounted) {
              setStateServices(response.data.data || []);
            }

          })

      } catch (error) {
        console.log(error);
      }
    };

    getServicesAndShifts();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <GridPageWrapper>
      <Grid item xs={12}>
        <HeaderBreadcrumbs
          heading={
            isEdit
              ? `Edit Sub Service for ${currentService?.name || ""}`
              : `Add Sub Service for ${currentService?.name || ""}`
          }
          links={[
            { name: "Dashboard", href: "/" },
            {
              name: `${currentService?.name} subservices`,
              href: `/subservices/${currentService?.id}`,
            },
            { name: !isEdit ? "New" : "Edit" },
          ]}
        />
      </Grid>
      <SubServiceForm
        isEdit={isEdit}
        currentSubService={currentSubService?.subservice}
        subServiceId={id}
        setCurrentSubService={setCurrentSubService}
        currentService={currentService}
      />
    </GridPageWrapper>
  );
};
export default AddSubService;
