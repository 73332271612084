import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
// material
import { Box, Button, Typography, Container } from "@mui/material";
// components

import PermissionsIllustration from "../assets/images/405";

// ----------------------------------------------------------------------

const RootStyle = styled("main")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function NoPermissionsPage() {
  return (
    <RootStyle title='405 Permission Error | Carpe Diem App'>
      <Container>
        <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
          <Typography variant='h3' paragraph>
            Access forbiden
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            The page you're trying access has restricted access. Please refer to
            your system administrator
          </Typography>

          <PermissionsIllustration
            sx={{ height: 260, my: { xs: 5, sm: 10 } }}
          />

          <Button
            to='/'
            size='large'
            variant='contained'
            component={RouterLink}
          >
            Go to Home
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
