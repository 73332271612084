import React, {useEffect, useRef, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useUtilityContext} from "../../context/utility/utilityContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import {useNavigate, useLocation } from "react-router-dom";
import {useAuthContext} from "../../context/auth/authContext"; // Add this import

const NotificationButton = () => {
    const axiosPrivate = useAxiosPrivate();
    const {setShowExtraFilters,  setShowNotApproved} = useUtilityContext();
    const {user} = useAuthContext();
    const [storedBadgeNum, setStoredBadgeNum] = useLocalStorage('cpd_badgeNum', null);
    const navigate = useNavigate();
    const location = useLocation();
    const onBadgeClick = () => {
        if (location.pathname !== "/") {
            navigate("/");
        }
        setShowExtraFilters(true);
        setShowNotApproved(true);
    }

    const getBadgeNum = async () => {
        const url  = `/orders?show_only_total=1&approved=0`;
        try {
            const response = await axiosPrivate(url);

            if (response.data.code === 200) {
                setStoredBadgeNum(response.data.data.total); // Store the badge number
            }

            if(response.data.code === 320) {
                setStoredBadgeNum(0); // Store the badge number
            }
        } catch (error) {
            console.log(error);
        }
    };
    const isFirstRender = useRef(true);
    useEffect(() => {

        if (!isFirstRender.current) {
            getBadgeNum();
            console.log("UPDATED CALLD")
        } else {
            isFirstRender.current = false;
        }


    },[user.franchise])

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        // First call
        if (!storedBadgeNum) {
            getBadgeNum();

        }

        // Scheduled calls
        const intervalId = setInterval(getBadgeNum, 60000); // Call getBadgeNum every 60 seconds
        return () => {
            isMounted = false;
            controller.abort();
            clearInterval(intervalId);
        };
    }, [storedBadgeNum]);

    return (
        <IconButton
            size='large'
            edge='start'
            color='inherit'
            className='notificationIcon'
            onClick={onBadgeClick}
        >
            {storedBadgeNum > 0 ? (
                <Badge badgeContent={storedBadgeNum} color="error">
                    <NotificationsIcon sx={{ color: "white" }} fontSize='large' />
                </Badge>
            ) : (
                <NotificationsIcon sx={{ color: "white" }} fontSize='large' />
            )}
        </IconButton>
    );
};

export default NotificationButton;
