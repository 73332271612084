import styled from "styled-components";

export const SearchInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
   input {
     
     width: 200px;
     border: 2px solid black;
     font-size: 1.2rem;
     padding: 5px 15px;
     margin-right: 15px;
      
     
     &:focus-visible {
       outline: none !important;
     }


   }

`
