const convertRoles = (rawData) => {

  if (rawData === "All") {
    return "All";
  }

  let roles = [];
  const types = Object.entries(rawData);

  types.forEach(([key, value]) => {
    let tempType = { name: key, value: Object.keys(value) };
    roles.push(tempType);
  });

  return roles;
};

export const getIndividualRoles = (data) => {
  if (data === "All") {
    return "All";
  }

  let user = [];
  let service = [];
  let group = [];
  let shift = [];
  let order = [];
  let dashboard = [];
  let franchise = []
  data.forEach((role) => {
    if (role.name === "User") {
      user = role.value;
    }

    if (role.name === "Service") {
      service = role.value;
    }

    if (role.name === "Group") {
      group = role.value;
    }

    if (role.name === "Shift") {
      shift = role.value;
    }

    if (role.name === "Order") {
      order = role.value;
    }

    if (role.name === "Dashboard") {
      dashboard = role.value;
    }

    if (role.name === "Franchise") {
      franchise = role.value;
    }
  });

  return { user, service, group, shift, order, dashboard, franchise };
};

export default convertRoles;
