import styled from "styled-components";

const CtaBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  button,
  a {
    padding: 25px 15px;
    width: 100%;
    color: white;
    margin-bottom: 15px;
    @media (min-width: 768px) {
      width: 47%;
      padding: 50px 15px;
    }
  }
`;

export const ReservationTitleBox = styled.div`
    display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .cta-buttons {
  
    @media (max-width: 992px) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    @media (max-width: 575px) {
      flex-direction: column;
      padding-top: 20px;
      width: 100%;
    }
    button {
      margin: 0 15px 0 0;
      @media (max-width: 992px) {
        width: 48%;
        margin: 5px 0;
      }
      @media (max-width: 575px) {
        margin: 0 0 10px 0;
      }
      @media (max-width: 425px) {
        margin: 10px 0;
        width: 100%;
      }
      
    }
    
    button {
      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
`

export default CtaBox;
