import * as React from "react";
import {DebounceInput} from 'react-debounce-input';
import  {SearchInputWrapper} from "./styles"
import {Button} from "@mui/material";


const SearchClientsInput = ({handleShowClientNames, showClientNames, clients, searchClientsFunc, uniqueSubservices=[],hideTimeRange, showTimeRange }) => {


    const handleChange = (value) => {

            uniqueSubservices.forEach((subservice) => {
                const id = subservice.id;
                if(value.length > 0 ) {
                  hideTimeRange(id);
                } else {
                    showTimeRange(id)
                }
            });

        const filteredClients = clients.filter((client) => {
            return client.name.toLowerCase().includes(value.toLowerCase());
        });

        clients.forEach((client) => {
            const { id } = client;
            searchClientsFunc(filteredClients, id)
        });
    };


    return (
        <SearchInputWrapper>

            {showClientNames &&   <DebounceInput
                minLength={2}
                className="search"
                placeholder="Search Clients"
                debounceTimeout={500}
                onChange={e => handleChange(e.target.value)} />}

            {

                handleShowClientNames &&
                <Button variant='contained' onClick={handleShowClientNames}>
                    {!showClientNames ? "Show Client Names" : "Hide Client Names"}
                </Button>
            }


        </SearchInputWrapper>

    );
};



export default SearchClientsInput;
