import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState, useEffect} from "react";
import {ModalListWrapper } from "./styles"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from '@mui/material/CircularProgress';
import {makeStyles} from "@material-ui/core/styles";
import AlertDialog from "../../helpers/AlertDialog";
import {useBookingContext} from "../../../context/booking/bookingContext";
import SingleReservationModalPopover from "../../helpers/SingleReservationModalPopover";
import {fMaxTwoDecimals} from "../../../utlis/formatNumber";
const useStyles = makeStyles((theme) => ({
    dialogTitleCstm: {
        fontSize:"2rem", borderBottom:"3px solid #D8D8D8", display:"flex", justifyContent:"space-between", alignItems:"center",

    },

    inner: {
        display:"flex",
        alignItems:"center",
        flexWrap:"wrap"
    },
    text: {
        paddingRight:"10px",
    },

    "@media (max-width: 768px)": {
        dialogTitleCstm: {
            fontSize:"1.5rem",

        },
    },
}));
export default function SingleReservationModal({order, setArrivedState, canApprove}) {

    const classes = useStyles();
    const {updateStateOrders} = useBookingContext();
    const axiosPrivate = useAxiosPrivate();
    const [orderDetails, setOrderDetails] = useState({})
    const [orderLogs, setOrderLogs] = useState([])


    const {
        id,
        name,
        email,
        phone,
        date_created,
        created_by,
        status,
        editor_name,
        arrived_at,
        note,
        items,
        approved,
        bruto
    } = orderDetails




    const [openOrderModal, setOpenOrderModal] = useState(false);


    const handleClickOpen = () => {
        setOpenOrderModal(true);
        getOrder()
    };

    const handleClose = () => {
        setOpenOrderModal(false);
    };

    let statusText;
    switch (status) {
        case "1":
            statusText = "In progress";
            break;
        case "2":
            statusText = "Finished";
            break;

            case "4":
            statusText = "Stopped";
            break;

        default:
            statusText = "In progress";
            break;
    }

    const getOrder = async () => {
        try {
            const response = await axiosPrivate(`/order/${order}`);

            if (response.data.code === 200 ) {
                setOrderDetails(response.data.data);

            } else {
                setOrderDetails({});
            }

        } catch (error) {
            console.log(error);
        }
    };
    const handlePersonArrivedClick = async () =>  {
        try {
            const response = await axiosPrivate.patch(
                `/order/arrival/${id}` );

            if (response?.data.code === 200) {
                setOrderDetails(response.data.data);
                updateStateOrders(response.data.data)
                setOpenOrderModal(false);
                setArrivedState(prev => !prev)

            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleApproveOrderClick = async () =>  {
        try {
            const response = await axiosPrivate.patch(
                `/order/approve/${id}` );

            if (response?.data.code === 200) {
                setOrderDetails(response.data.data);
                updateStateOrders(response.data.data)
                setOpenOrderModal(false);
                //DODAJES DA RADI FETCH NA ODRER TABLE I TO JE TO
                setArrivedState(prev => !prev)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleItemPersonArrivedClick = async (itemId) =>  {

        try {
            const response = await axiosPrivate.patch(
                `/order/item/arrival/${itemId}` );

            if (response?.data.code === 200) {

                setOrderDetails(response.data.data);
                updateStateOrders(response.data.data)
                setOpenOrderModal(false);
                setArrivedState(prev => !prev)
            }
        } catch (error) {
            console.error(error);
        }
    }


    const handleGetLogsForOrder = async () =>  {
        try {
            const response = await axiosPrivate(
                `/order/logs/${id}` );

            if (response?.data.code === 200) {
                setOrderLogs(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    }





    return (
        <React.Fragment>

            <IconButton onClick={handleClickOpen} >
                <VisibilityIcon />
            </IconButton>

            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={openOrderModal}
                onClose={handleClose}

            >
                {Object.keys(orderDetails).length > 0 ?
                    <>
                    <DialogTitle className={classes.dialogTitleCstm} >
                        <div className={classes.inner}>
                            <p className={classes.text}>ORDER ID: #{id}</p>

                            <AlertDialog
                                target={ <Button
                                    color='primary'
                                    variant='contained'
                                    disabled={arrived_at !== null ? true : false}

                                >
                                    Person Has Arrived
                                </Button>}
                                onAction={handlePersonArrivedClick}
                                isDisabled={arrived_at !== null ? true : false}

                            />

                            {(approved === "0" && canApprove) &&
                                <div style={{marginLeft: "15px"}}>
                                    <AlertDialog
                                        target={ <Button
                                            color='primary'
                                            variant='contained'

                                        >
                                            Approve
                                        </Button>}
                                        onAction={handleApproveOrderClick}

                                    />
                                </div>

                            }



                        </div>

                        <IconButton onClick={handleClose} className='closeIconPopover'>
                            <CloseIcon color='error' fontSize="large"/>
                        </IconButton>

                    </DialogTitle>
                    <DialogContent>

                        <ModalListWrapper>

                            <dl className="infoDl">
                                <div>
                                    <dt>Name:</dt>
                                    <dd>{name || "Not entered"}</dd>
                                </div>

                                <div>
                                    <dt>Email:</dt>
                                    <dd>{email ? <a href={`mailto:${email}`}>{email} </a> : "Not entered" }</dd>
                                </div>

                                <div>
                                    <dt>Phone:</dt>
                                    <dd>{phone ? <a href={`tel:${phone}`}>{phone} </a> : "Not entered" }</dd>
                                </div>

                                <div>
                                    <dt>Notes:</dt>
                                    <dd>{note}</dd>
                                </div>
                            </dl>


                            <dl className="statusDl">
                                <div>
                                    <dt>Status:</dt>
                                    <dd>{statusText}</dd>
                                </div>

                                <div>
                                    <dt>Current Editor:</dt>
                                    <dd>{editor_name || ""}</dd>
                                </div>

                                <div>
                                    <dt>Created At:</dt>
                                    <dd>{moment(date_created).format("hh:mm DD.MM.YY ") }</dd>
                                </div>

                                <div>
                                    <dt>Created By:</dt>
                                    <dd>{created_by || ""}</dd>
                                </div>
                            </dl>

                            <div className="services-wrapper">
                                <div className="heading-banner">
                                    <span>Service</span>
                                    <div><span>From</span> <span>To</span> </div>
                                    <span className="bruto">Bruto</span>
                                </div>
                                <div className="items-wrapper">
                                    {items?.map(item => {
                                        return <div className="single-item" key={item.id}>
                                            <div className="left">
                                                <p>{item.service_name}</p>
                                                <span>{item.name}</span>
                                            </div>

                                            <div className="center">
                                                <p>{moment(item.time_from).format("DD.MM.YY")} <span>{moment(item.time_from).format("hh:mm")}</span></p>
                                                <p>{moment(item.time_to).format("DD.MM.YY")} <span>{moment(item.time_to).format("hh:mm")}</span></p>

                                            </div>

                                            <div className="right">
                                                <p>{fMaxTwoDecimals(item.bruto)} €</p>
                                            </div>
                                            <AlertDialog
                                                target={ <Button
                                                    color='primary'
                                                    variant='contained'
                                                    disabled={item.arrived !== "0" ? true : false}

                                                >
                                                    Person Has Arrived
                                                </Button>}
                                                onAction={() => handleItemPersonArrivedClick(item.id)}
                                                isDisabled={item.arrived !== "0" ? true : false}

                                            />

                                        </div>
                                    })}

                                </div>

                                <div className="total-wrapper">
                                    <p>TOTAL: {fMaxTwoDecimals(bruto)} €</p>
                                </div>

                            </div>

                            <div className="logs-wrapper">
                                <Button variant="contained" onClick={handleGetLogsForOrder} sx={{marginTop:"10px"}}>Get Logs</Button>
                                {orderLogs?.length > 0 &&   <div className="heading-banner">
                                    <div>
                                        <span>Log ID</span>
                                        <span>User ID</span>
                                    </div>

                                    <div className="cta-div"><span>Date</span> <span>Action</span> </div>
                                </div>}

                                <div className="items-wrapper">
                                    {orderLogs?.map(item => {
                                        return <div className="single-item" key={item.id} >
                                            <div className="left">

                                                <p>{item.id}</p>
                                                <span>{item.user}</span>
                                                <SingleReservationModalPopover itemId={item.id}/>
                                            </div>

                                            <div className="right">
                                                <p>{moment(item.datum).format('lll')}</p>
                                                <p>{item.action}</p>

                                            </div>
                                        </div>
                                    })}
                                </div>

                            </div>

                        </ModalListWrapper >

                    </DialogContent>
                    </>
                  :
                    <Box sx={{ display: 'flex', alignItems:"center", justifyContent:"center", minWidth:"100%", minHeight:"250px;" }}>
                        <CircularProgress />
                    </Box>
                }

                <DialogActions sx={{paddingBottom:"25px"}}>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
