
import {useBookingContext} from "../context/booking/bookingContext";
import moment from "moment";

export const useTimesShiftsConversion = () => {
    const {
        shifts,
        selectedShift,
        orderDate
    } = useBookingContext();


    const selectedShiftValue = shifts.find((shift) => shift.id === selectedShift);
    let timeFrom = selectedShiftValue?.time_from.substring(0, 2);
    let timeTo = selectedShiftValue?.time_to.substring(0, 2);
    timeFrom = parseInt(timeFrom, 10);
    timeTo = parseInt(timeTo, 10);

    if(timeTo === 0) {
        timeTo = 24;
    }

    let times = [];
    let shiftLength;
    let startingInterval;
    let endInterval;

    if (timeFrom > timeTo) {
        startingInterval = `${moment(orderDate).format("YYYY-MM-DD")} ${
            selectedShiftValue?.time_from
        }`;
        endInterval = `${moment(orderDate).add(1, "days").format("YYYY-MM-DD")} ${
            selectedShiftValue?.time_to
        }`;
        shiftLength = (24 - timeFrom) * 60 + timeTo * 60;
    } else {
        startingInterval = `${moment(orderDate).format("YYYY-MM-DD")} ${
            selectedShiftValue?.time_from
        }`;
        if(timeTo === 24) {
            endInterval = `${moment(orderDate).add(1, "days").format("YYYY-MM-DD")} ${
                selectedShiftValue?.time_to
            }`;
        } else {
            endInterval = `${moment(orderDate).format("YYYY-MM-DD")} ${
                selectedShiftValue?.time_to
            }`;
        }

        shiftLength = (timeTo - timeFrom) * 60;

    }


    startingInterval = moment(new Date(startingInterval.replace(/-/g, "/")));
    endInterval = moment(new Date(endInterval.replace(/-/g, "/")));


    const getIntervals = (startString, endString) => {
        let start = moment(startString, "YYYY-MM-DD hh:mm a");
        let end = moment(endString, "YYYY-MM-DD hh:mm a");

        start.minutes(Math.ceil(start.minutes() / 15) * 15);
        let current = moment(start);

        while (current <= end) {
            times.push(current.format("YYYY-MM-DD HH:mm"));
            current.add(15, "minutes");
        }

        return times;
    };

    getIntervals(startingInterval, endInterval);


    return {times, shiftLength};
};
