import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import LandingWrapper from "../assets/wrappers/LandingWrapper";
import main from "../assets/images/landing.svg";
import Logo from "../components/Logo";
import LoginForm from "../components/LoginForm";
import { useAuthContext } from "../context/auth/authContext";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

const Landing = () => {

  const cpd_deviceId = localStorage.getItem("cpd_deviceId");
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState();
  const [deviceid, setDeviceId] = useState(cpd_deviceId ? JSON.parse(cpd_deviceId) : uuid())

  const { user, showAlert, loginUser } = useAuthContext();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });

    if (e.target.value.length > 0) {
      setErrors((prev) => {
        return { ...prev, [e.target.name]: false };
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const username = form.username;
    const password = form.password;

    if (!username) {
      setErrors((prev) => {
        return { ...prev, username: true };
      });
    }

    if (!password) {
      setErrors((prev) => {
        return { ...prev, password: true };
      });
    }
    if (username && password && deviceid) {
      loginUser({ username, password, deviceid, alertText: "Login Successful!" });
      localStorage.setItem("cpd_deviceId", JSON.stringify(deviceid));
    }
  };

  useEffect(() => {
    if (user) {
      navigate(from);
    }
  }, [user, navigate]);

  return (
    <LandingWrapper
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
    >
      <Grid item xs={12} md={12} lg={6} className='main-grid-col'>
        <div className='inner'>
          <Logo />

          <LoginForm
            showAlert={showAlert}
            handleChange={handleChange}
            onSubmit={onSubmit}
            errors={errors}
          />
        </div>
      </Grid>

      <Grid
        item
        lg={6}
        sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}
      >
        <img src={main} alt='landing img' className='decoration-image' />
      </Grid>
    </LandingWrapper>
  );
};
export default Landing;
