import { Button, Grid, Skeleton } from "@mui/material";
import GridPageWrapper from "../../assets/wrappers/GridPageWrapper";
import SingleServiceList from "../../components/dashboard/services/SingleServiceList";
import HeaderBreadcrumbs from "../../components/layoutHelpers/HeaderBreadcrumbs";
import { useBookingContext } from "../../context/booking/bookingContext";
import { Link as RouterLink } from "react-router-dom";
import { MdPersonAdd } from "react-icons/md";
import { useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import usePermissions from "../../hooks/usePermssions";
import { useAuthContext } from "../../context/auth/authContext";

const ServicesList = () => {
  const axiosPrivate = useAxiosPrivate();
  const { services, setStateServices } = useBookingContext();
  const { user } = useAuthContext();
  const permissons = usePermissions("service");

  const SERVICES_URL = "/services";

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getServices = async () => {
      try {
        const response = await axiosPrivate(SERVICES_URL, {
          signal: controller.signal,
        });

        if (response.data.code === 200 && isMounted) {
          setStateServices(response.data.data || []);
        } else {
          isMounted && setStateServices([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getServices();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [user.franchise]);

  return (
    <GridPageWrapper>
      <Grid item xs={12}>
        {permissons.all || permissons.create ? (
          <HeaderBreadcrumbs
            heading='Services List'
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Services List" },
            ]}
            action={
              <Button
                variant='contained'
                component={RouterLink}
                to={"add-service"}
                startIcon={<MdPersonAdd />}
              >
                New Service
              </Button>
            }
          />
        ) : (
          <HeaderBreadcrumbs
            heading='Services List'
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Services List" },
            ]}
          />
        )}

        {services ? (
          <SingleServiceList
            services={services}
            servicePermissons={permissons}
          />
        ) : (
          <Skeleton width='100%' height='700px' sx={{ marginTop: "-120px" }} />
        )}
      </Grid>
    </GridPageWrapper>
  );
};
export default ServicesList;
