import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useBookingContext } from "../../../context/booking/bookingContext";
import { useContainerDimensions } from "../../../hooks/useContainerDimensions";
import { ListViewWrapper } from "../../../pages/dashboard/booking/styles";
import SingleSubService from "./SingleSubService";
import { v4 as uuid } from "uuid";
import { useSnackbar } from "notistack";
import SearchClientsInput from "./SchemeHelpers/SearchClientsInput";

const SubservicesList = ({ times, shiftLength, orderDate, dynamicVar }) => {
  const {
    subservices,
    selectedService,
    setAllSelectedTimeDurations,
    selectedTimeDurations,
    setAllSelectedItemsInCurrentlyEditedRes,
  } = useBookingContext();
  const [clients, setClients] = useState([]);
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);
  const pxValue = width / (shiftLength - 150);
  const { enqueueSnackbar } = useSnackbar();
  let timeLine = times.filter((_, i) => i % 4 == 0);
  timeLine = timeLine.map((time) => {
    return time.substr(time.length - 5);
  });

  const spanWidth = 60 * pxValue;

  const itemCount = subservices[selectedService.id]?.length + 1;
  let timelineHeight = (67 + 15) * itemCount - 53;
  // let timelineHeight = (height + 15) * itemCount - 53;
  useEffect(() => {
    selectedTimeDurations?.forEach((timeDuration) => {
      if (timeDuration.order_names) {
        setClients((prevState) => [
          ...prevState,
          {
            id: timeDuration.subservice,
            name: timeDuration.order_names,
          },
        ]);
      }
    });
  }, [selectedTimeDurations.length]);

  const handleSelectAllClick = () => {
    let snackbarArray = [];
    let selectAllArray = subservices[selectedService.id || 0].map((sub) => {
      return {
        id: uuid(),
        subservice: sub.id,
        date: orderDate,
        offsetPrecentage: 0,
        time_from: times[0] + ":00",
        time_to: times[times.length - 1] + ":00",
        durationPrecentage: 100,
        isEditable: true,
        state_item: true,
      };
    });
    selectedTimeDurations.forEach((std) => {
      selectAllArray.forEach((newStd) => {
        if (std.subservice === newStd.subservice) {
          snackbarArray.push(std.name);
        }
      });
    });

    if (snackbarArray.length > 0) {
      enqueueSnackbar(
        `${[...snackbarArray]} is already reserved and is not selected!`,

        {
          variant: "warning",
          vertical: "top",
          horizontal: "left",
        },
      );
    }

    selectedTimeDurations.forEach((item, index, array) => {
      let ItemIndex = selectAllArray.findIndex(
        (b) => b.subservice === item.subservice,
      );
      selectAllArray.splice(ItemIndex, 1);
    });

    setAllSelectedTimeDurations(selectAllArray);
    setAllSelectedItemsInCurrentlyEditedRes(selectAllArray);
  };

  const hideTimeRange = (id) => {
    const timeRangeElement = document.getElementById(`${id}`);

    if (timeRangeElement) {
      timeRangeElement.style.opacity = "0";
      timeRangeElement.style.height = "0";
      timeRangeElement.style.paddingBottom = "0";
      timeRangeElement.style.pointerEvents = "none";
    }
  };

  const showTimeRange = (id) => {
    const timeRangeElement = document.getElementById(`${id}`);
    if (timeRangeElement) {
      timeRangeElement.style.opacity = "1";
      timeRangeElement.style.height = "100%";
      timeRangeElement.style.paddingBottom = "15px";
      timeRangeElement.style.pointerEvents = "auto";
    }
  };
  const searchClientsFunc = (filteredClients, id) => {
    if (filteredClients.some((c) => c.id === id)) {
      showTimeRange(id);
    } else {
      hideTimeRange(id);
    }
  };
  const uniqueSubservices = subservices[selectedService.id || 0]?.filter(
    (subservice) => !clients.some((client) => client.id === subservice.id),
  );

  return (
    <>
      <SearchClientsInput
        showClientNames={true}
        clients={clients}
        searchClientsFunc={searchClientsFunc}
        uniqueSubservices={uniqueSubservices}
        hideTimeRange={hideTimeRange}
        showTimeRange={showTimeRange}
      />
      <ListViewWrapper>
        {subservices[selectedService.id] ? (
          <>
            <Button
              color="primary"
              variant="contained"
              className="select-all-btn"
              onClick={handleSelectAllClick}
            >
              Select All
            </Button>
            {timeLine.length > 0 && (
              <div className="timeline-grid" style={{ height: timelineHeight }}>
                {pxValue !== 0 &&
                  timeLine.map((time) => {
                    return (
                      <div key={uuid()} style={{ width: spanWidth || "auto" }}>
                        <span>{time}</span>
                      </div>
                    );
                  })}
              </div>
            )}
            {times.length > 0 && selectedService && (
              <div className="subservice-wrapper">
                {subservices[selectedService.id || 0] &&
                  subservices[selectedService.id || 0].map((sub) => {
                    return (
                      <SingleSubService
                        key={sub.id}
                        {...sub}
                        times={times}
                        shiftLength={shiftLength}
                        ref={componentRef}
                        orderDate={orderDate}
                        selectedService={selectedService}
                        dynamicVar={dynamicVar}
                        subserviceId={sub.id}
                      />
                    );
                  })}
              </div>
            )}
          </>
        ) : (
          <>
            <p>No subservices added for selected service!</p>
          </>
        )}
      </ListViewWrapper>
    </>
  );
};
export default SubservicesList;
