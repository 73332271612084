import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useRef, useState} from "react";
import CountryCodeSelect from "./CountryCodeSelect";
import AddIcCallIcon from '@mui/icons-material/AddIcCall';

function CountryCodeDialog({handleCountryCodeSelect, countryCode}) {
    const [open, setOpen] = useState(false);
    const inputRef = useRef(null);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleInputFocus = () => {
        setOpen(true);
        inputRef.current.blur();
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <div onClick={handleClickOpen} className="countryCodeBtn" onFocus={handleInputFocus}>
                {countryCode ? <input type="text" onFocus={handleInputFocus} value={countryCode} readOnly  ref={inputRef}/> : <> <input type="text" value="" className="emptyInput" onFocus={handleInputFocus} readOnly  ref={inputRef}/> <AddIcCallIcon/></>}
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Country Code</DialogTitle>
                <DialogContent>
                    <CountryCodeSelect onSelect={handleClose} handleCountryCodeSelect={handleCountryCodeSelect} countryCode={countryCode}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CountryCodeDialog;
